import React from "react";

const Brucepewpew = () => {
  return (
    <div style={{ textAlign: "center" }}>
      <iframe
        src="/brucepewpew/index.html"
        width="1920"
        height="1080"
        style={{ border: "none" }}
        title="Game"
      />
    </div>
  );
};
  
export default Brucepewpew;